
export class AppSettings {
    public static IMAGES_DIR = 'assets/imgs';
    public static DOCS_PER_PAGE: number = 7;
    public static NONE_VALUE_SELECTED = 'None selected';

    /**
     * 
     * @param pageNumber
     * @returns offset
     */
    public static getOffset(pageNumber: number): number {
        return AppSettings.DOCS_PER_PAGE * (pageNumber - 1);
    }
}
