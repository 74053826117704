import { BusinessUnit } from "./businessUnit";
import { Category } from "./category";
import { Language } from "./language";
import { Region } from "./region";

export class Document {

    name: string;
    category: string;
    fileName: string;
    regions: Region[];
    languages: Language[];
    businessUnits: BusinessUnit[];
    extract: string | null;
    title: string | null;
    important: string;
    owner: string | null;
    creation: string | null;

    constructor(name: string,
                category: string,
                fileName: string,
                regions: Region[],
                languages: Language[],
                businessUnits: BusinessUnit[],
                extract: string | null,
                title: string | null,
                important: string,
                owner: string | null,
                creation: string | null) {

        this.name = name;
        this.category = category;
        this.fileName = fileName;
        this.regions = regions;
        this.languages = languages;
        this.businessUnits = businessUnits;
        this.extract = extract;
        this.title = title;
        this.important = important;
        this.owner = owner;
        this.creation = creation;
    }
}
