import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangePageService {

  pageNumber: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  constructor() { }

  getStatus(): Observable<number> {
    return this.pageNumber.asObservable();
  } 

  nextPage(pageNumber: number): void {
    this.pageNumber.next(pageNumber);
  }
}
