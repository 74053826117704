import { Component, Input, OnInit } from '@angular/core';
import { AppSettings } from '../../app-settings';

@Component({
  selector: 'app-search-error',
  templateUrl: './search-error.component.html',
  styleUrls: ['./search-error.component.css']
})
export class SearchErrorComponent implements OnInit {

  @Input() error: string;
  warningIconPath: string = `../../../${AppSettings.IMAGES_DIR}/warning_icon.svg`;

  constructor() { }

  ngOnInit(): void {
  }

}
