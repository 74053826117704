import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  isVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() { }

  ngOnInit(): void {
  }

  getStatus(): Observable<boolean> {
    return this.isVisible.asObservable();
  }

  changeVisbility(isVisible: boolean): void {
    this.isVisible.next(isVisible)
  }
}
