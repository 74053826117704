import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { AuthService } from 'src/app/services/auth.service';
import { SeguridadService } from 'src/app/services/seguridad.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup
  userIconPath: string = `../../../${AppSettings.IMAGES_DIR}/user_icon.svg`;

  constructor(private seguridadService: SeguridadService,
              private router: Router,
              private formBuilder: FormBuilder, 
              private authService: AuthService) { }

  ngOnInit(): void {
    this.createForm()
  }

  private createForm(): void {
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: ['']
    })
  }

  // quick access for from controls
  get fc() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.seguridadService.login(this.fc.username.value, this.fc.password.value)
        .subscribe(
          data => {
            this.onSuccessLogin()
          },
          err => {
            this.onErrorLogin()
          }
        )
  }

  private onSuccessLogin() {
    this.authService.updateIsAuthenticated(true)
    this.router.navigate(['/docs'])
  }

  private onErrorLogin() {
    Swal.fire({
      icon: 'error',
      title: 'Authentication error: either password or username are wrong',
      confirmButtonColor: '#f27474'
    })
  }
}
