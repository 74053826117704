
export class Category {

    name: string;
    parentCategory: string;
    isGroup: number;

    constructor(name: string, parentCategory: string, isGroup: number) {
        this.name = name;
        this.parentCategory = parentCategory;
        this.isGroup = isGroup;
    }
}