<h3>{{ "Resultados" | translate }}</h3>
<div *ngIf="!searchError; else searchErrMsge">
    <div *ngIf="isSpinnerVisible; else documentsResults">
        <app-spinner></app-spinner>
    </div>

    <ng-template #documentsResults>
        <div *ngFor="let doc of documents |
        paginate: {
            itemsPerPage: docsPerPage,
            currentPage: currentPage,
            totalItems: totalDocuments
        }">
        <app-document [document]="doc"></app-document>
        </div>
        <div *ngIf="hasDocumentsResult(); else noResultMsge" class="pagination-controls">
            <pagination-controls
                style="text-align: center;"
                previousLabel="Previous"
                nextLabel="Next"
                (pageChange)="handlePageChange($event)">
            </pagination-controls>
        </div>
        <ng-template #noResultMsge>
            <app-no-docs-result></app-no-docs-result>
        </ng-template>
    </ng-template>
</div>
<ng-template #searchErrMsge>
    <app-search-error [error]="searchError"></app-search-error>
</ng-template>
