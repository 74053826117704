<!-- <div class="container">
    <div class="row">
        <img class="col-md-12" [src]="emptyImgPath" alt="">
    </div>
    <div class="row">
        <p class="col-md-12">No results with the given search</p>
    </div>
</div> -->
<!-- <div class="container"> -->
<div class="container">
    <div class="d-flex justify-content-center">
        <div>
            <img [src]="emptyImgPath" id="emptyImg"  alt="">
        </div>

    </div>
    <div class="d-flex justify-content-center">
        <p>No result with the given search</p>
    </div>
</div>
<!-- </div> -->