import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeguridadService {

  loginUrl: string = '/api/method/login';

  constructor(private http: HttpClient) { }

  login(user: string, pass: string): Observable<any> {
    const body = JSON.stringify({usr: user, pwd: pass})

    return this.http.post(this.loginUrl, 
                          body,
                          {headers: this.getHeaders()})
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({'Accept': 'application/json', 'Content-Type': 'application/json'})
  }
}
