import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveMakerService {

  constructor() { }

  verticalScreenAdjust() {
    let height = window.innerHeight + 60
    document.getElementById("search-component")!.style.height = height + "px";
  }
}