import { ViewportScroller } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { ModelConverterService } from 'src/app/services/model-converter.service';
import { AppSettings } from '../../app-settings';
import { Document } from '../../model/document';
import '@fortawesome/fontawesome-free/css/all.css';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent implements OnInit {
  isDeutchLang: boolean;
  isEnLang: boolean;
  isItLang: boolean;
  isFrLang: boolean;
  isEsLang: boolean;
  isPoLang: boolean;
  public documentos: any[] = [];
  public categorias: any[] = [];
  public categoria: any[] = [];
  productos: any[] = [];
  private baseUrl = '/Category';
  private basePath = '/api/resource';
  public data: any;
  collapsed: { [key: string]: boolean } = {};
  collapseds: { [key: string]: boolean } = {
    collapseDatasheet: true,
  };
  productName: string;
  categoryName: string;
  loadingRenting: boolean = false;
  isLoading: boolean = false;
  faSpinner = faSpinner;
  public documentosFiltrados: any[] = [];
  categoriasPorProducto: { [key: string]: any[] } = {};
  lastOpenedId: string | null = null;
  mostrarMensajeNoDocumentos: boolean = false;
  lenguageActual: string; 





  @Input() document: Document;
  fileIconPath: string = `../../../${AppSettings.IMAGES_DIR}/file_icon.svg`;
  infoIconPath: string = `../../../${AppSettings.IMAGES_DIR}/info_icon.svg`;
  downloadIconPath: string = `../../../${AppSettings.IMAGES_DIR}/download_icon.svg`;
  documentImg: string = `../../../assets/imgs/documentos.svg`;

  constructor(
    private viewportScroller: ViewportScroller,
    private translate: TranslateService,
    private api: ApiService,
    private modelConverter: ModelConverterService,
    private http: HttpClient
    
  ) {
    this.isDeutchLang = this.translate.currentLang === 'de';
    this.isEnLang = this.translate.currentLang === 'en';
    this.isItLang = this.translate.currentLang === 'it';
    this.isFrLang = this.translate.currentLang === 'fr';
    this.isEsLang = this.translate.currentLang === 'es';
    this.isPoLang = this.translate.currentLang === 'po';
    this.lenguageActual = this.translate.currentLang;

  }

  ngOnInit() {
    this.getCategorias();
    this.getProductos();
  
    this.lenguageActual = this.translate.currentLang;

    this.translate.onLangChange.subscribe((event) => {
      this.isDeutchLang = event.lang === 'de';
      this.isEnLang = event.lang === 'en';
      this.isItLang = event.lang === 'it';
      this.isFrLang = event.lang === 'fr';
      this.isEsLang = event.lang === 'es';
      this.isPoLang = event.lang === 'po';
      this.lenguageActual = event.lang;
    });
  }
  
  toggleCollapse(id: string, productName: string, categoryName: string, event: MouseEvent) {

    event.stopPropagation();

    if (this.lastOpenedId === id) {
        return;
    }

    this.productName = productName;
    this.categoryName = categoryName;

    this.collapsed[id] = !this.collapsed[id];

    if (!this.collapsed[id]) {
        this.lastOpenedId = id;
    }

    if (!this.collapsed[id] && this.productName && this.categoryName) {
        Object.keys(this.collapsed).forEach(key => {
            if (key !== id) {
                this.collapsed[key] = true;
            }
        });
        this.getDocumentos(this.productName, this.categoryName);
    }
}
closeIfSameCategory(id: string, event: MouseEvent) {
  if (this.lastOpenedId === id) {
      this.collapsed[id] = true;
      this.lastOpenedId = null;

  }
}



  hayDocumentosParaCategoria(productoName: string, categoryName: string): boolean {
    return this.documentos.some(doc => doc.category === categoryName && doc.name === productoName);
  }


  getCategoriasPorProducto(producto: string) {
    return this.http.get(`/api/method/docs_management.documents.getCategoriasPorProducto?producto=${producto}`)
      .pipe(
        tap((response: any) => {
          console.log(`Respuesta del servidor para ${producto}:`, response); 
          const categorias = response.message.categorias; 
          console.log(`Categorías para ${producto}:`, categorias);
  
          this.categoriasPorProducto[producto] = categorias;
        }),
        catchError(error => {
          console.error(`Error al obtener las categorías para ${producto}:`, error);
          return throwError(error);
        })
      );
  }
  
  
  

  getDocumentos(productName: string, categoryName: string) {
    this.loadingRenting = true;

  
    let formData = {
      bussinessunit: productName,
      categoria: categoryName,
      language: this.lenguageActual
    };
    console.log('Languages:', formData.language); 

  
    this.api.getDocumentos(formData).subscribe({
      next: (r: any) => {
        const documentosFiltrados = r.message.documents.filter((doc: any) => doc.category === categoryName);
        console.log(documentosFiltrados);
        if (!documentosFiltrados.length) {
          this.mostrarMensajeNoDocumentos = true; 
        } else {
          this.mostrarMensajeNoDocumentos = false;
          documentosFiltrados.forEach((doc: any) => {
            console.log(doc.language);
          });
          this.documentos = documentosFiltrados;
        }
        this.loadingRenting = false;
      },
      error: (error: any) => {
        console.error("Error al obtener los documentos:", error);
        this.loadingRenting = false;
      }
    });
  }
  
  
  
  
  
  


  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  assignDocument(data: any) {
    this.document = this.modelConverter.convertDocument(data.data);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const scrollThreshold = 200;

    const scrollToTopButton = document.querySelector('.scroll-to-top');
    if (scrollToTopButton) {
      if (scrollPosition > scrollThreshold) {
        scrollToTopButton.classList.remove('hidden');
      } else {
        scrollToTopButton.classList.add('hidden');
      }
    }
  }

  filterDocumentos(productoName: string, categoryName: string): any[] {
    return this.documentos.filter(doc => doc.name == productoName && doc.category == categoryName);
  }

  getCategorias() {
    this.isLoading = true;

    this.api.getCategorias().subscribe({
      next: (r: any) => {
        this.categorias = r.message.categorias;
        console.log(r.message);
        this.configureCollapsedState();
        this.isLoading = false;

      }
    });
  }

  getProductos() {
    this.isLoading = true;

    this.api.getProductos().subscribe({
      next: (r: any) => {
        this.productos = r.message.products;
        console.log(r.message);
  

        this.productos.forEach(producto => {

          this.getCategoriasPorProducto(producto.name1).subscribe({
            next: (response: any) => {

              const categorias = response.categorias;
              console.log(`Categorías para ${producto.name1}:`, categorias);
              producto.categorias = categorias;
              this.isLoading = false;

            },
            error: (error: any) => {
              console.error(`Error al obtener las categorías para ${producto.name1}:`, error);
              this.isLoading = false;

            }
          });
        });
  
        this.configureCollapsedState();
      }
    });
  }
  
  

  configureCollapsedState() {
    this.productos.forEach((producto, i) => {
      this.categorias.forEach((categoria, j) => {
        const id = `${producto.name1}_${categoria.name}`;
        if (this.collapsed[id] === undefined) {
          this.collapsed[id] = true;
        }
      });
    });
  }


  downloadMultipleFiles(): void {
    const urls: string[] = [
      '/assets/HIGH RES RENDERS BatteryBox 2.zip',
      '/assets/HIGH RES RENDERS BatteryBox 3.zip'
    ];

    urls.forEach(url => {
      const link = document.createElement('a');
      link.href = url;
      link.download = url.split('/').pop() || 'archivo.zip';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
}
