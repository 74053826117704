
<div class="wrapper fadeInDown">
    <div id="formContent">

      <div class="fadeIn first">
        <img [src]="userIconPath" id="icon" alt="User Icon" />
      </div>

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <input type="text" id="login" class="fadeIn second" name="login" placeholder="Username" formControlName="username">
        <input type="password" id="password" class="fadeIn third" name="login" placeholder="Password" formControlName="password">
        <input type="submit" class="fadeIn fourth" id="login-button"  value="Log In">
    </form>
    </div>
</div>
