import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TranslateService } from '@ngx-translate/core';




@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isMenuOpen: boolean = false;
  isMenuCollapsed = true;
  isSubMenuActive = false; 
  isProductsActive = false;
  showSubmenu = false;
  isSubMenuOpen: boolean = false;
  isSpanishLang: boolean;
  isEnglishLang: boolean;
  isDeutchLang: boolean;
  isItLang: boolean;
  isFrLang: boolean;
  isPoLang: boolean;
  selectedLanguage: string;
  classApplied: boolean = false;



  languages = [
    { code: 'es', label: 'ESPAÑOL (ESPAÑA)' },
    { code: 'en', label: 'ENGLISH (UK/USA)' },
    { code: 'de', label: 'DEUTSCH (DEUTSCHLAND)' },
    { code: 'fr', label: 'FRANÇAIS (FRANCE)' },
    { code: 'it', label: 'ITALIANO (ITALIA)' },
    { code: 'po', label: 'POLISH (POLAND)' }
  ];


  switchLanguage(code: string) {
    this.translate.use(code);
    console.log(this.translate);
  }
  getSelectedLanguageLabel(): string {
    const selectedLang = this.languages.find(lang => lang.code === this.selectedLanguage);
    return selectedLang ? selectedLang.label : '';
  }
  
  
  get currentLanguage() {
    return this.translate.currentLang;
  }
  currentSection: string = ''; 


  changeSection(section: string) {
    this.currentSection = section;
    console.log(this.currentSection)
  }

  constructor(private viewportScroller: ViewportScroller, private route: ActivatedRoute, private router: Router,private translate: TranslateService) {
    this.isSpanishLang = this.translate.currentLang === 'es';
    this.isEnglishLang = this.translate.currentLang === 'en';
    this.isDeutchLang = this.translate.currentLang === 'de';
    this.isItLang = this.translate.currentLang === 'it';
    this.isFrLang = this.translate.currentLang === 'fr';
    this.isPoLang = this.translate.currentLang === 'po';

  }



preferredLang: string;

ngOnInit() {
  this.route.queryParamMap.subscribe(queryParams => {
    this.currentSection = queryParams.get('section') || ''; 
    this.highlightActiveSection(this.currentSection);
  });

  const browserLang = navigator.language.split('-')[0]; 
  const supportedLangs = this.languages.map(lang => lang.code);

  this.preferredLang = supportedLangs.includes(browserLang) ? browserLang : 'en';

  this.translate.use(this.preferredLang);
  console.log(this.preferredLang);

}

onSubMenuClick(langCode: string) {
  this.switchLanguage(langCode);
  this.preferredLang = langCode;  
  console.log('Preferred language:', this.preferredLang);
  this.closeMenu();
}

onSubMenuOptionClick(langCode: string) {
  this.selectedLanguage = langCode;
  this.switchLanguage(langCode);
  this.closeMenu();
}
onSubMenuOptionClicks(event: MouseEvent): void {
  // Lógica de manejo del clic aquí
}

getLanguageLabel(langCode: string): string {
  const selectedLang = this.languages.find(lang => lang.code === langCode);
  return selectedLang ? selectedLang.label : '';
}

getPreferredLanguageLabel(): string {
  const preferredLang = this.languages.find(lang => lang.code === this.preferredLang);
  return preferredLang ? preferredLang.label : '';
}


  closeMenu() {
    this.isMenuOpen = false;
    this.classApplied = false; 
    this.isSubMenuActive = false;

  }
  

  toggleClass() {
      this.classApplied = !this.classApplied;
  }
  

  onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
    this.highlightActiveSection(elementId);
    this.isMenuOpen = false; 
  }

  highlightActiveSection(sectionId: string): void {
    const navLinks = document.querySelectorAll('.navbar-nav .link-btn');
    navLinks.forEach(link => {
      link.classList.remove('active');
      const routerLink = link.getAttribute('href');
      if (routerLink && routerLink === `/${sectionId}`) {
        link.classList.add('active');
      }
    });
  }
  toggleSubMenu() {
    this.isSubMenuOpen = !this.isSubMenuOpen;
  }


  closeSubMenu() {
    this.isSubMenuActive = false;
  }


switchToSpanish() {
  this.translate.use('es');
}
switchToEnglish() {
  this.translate.use('en');
}
switchToDeuch() {
  this.translate.use('de');
}
switchToIt() {
  this.translate.use('it');
}
switchToFr() {
  this.translate.use('fr');
}
switchToPo() {
  this.translate.use('po');
}

  closeSubMenuOnOutsideClick() {
    this.isSubMenuOpen = false;
  }
  isSubMenuOpenForHamburger = false; 


  toggleSubMenuForHamburger() {
    this.isSubMenuOpenForHamburger = !this.isSubMenuOpenForHamburger;
  }

  recargarPagina() {
    location.reload();
}

}