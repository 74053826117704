import { Injectable } from '@angular/core';
import { BusinessUnit } from '../model/businessUnit';
import { Category } from '../model/category';
import { Language } from '../model/language';
import { Region } from '../model/region';
import { Document } from '../model/document';

/**
 * TODO: convert data in JSON format in the specific object
 * model given by methods of the class
 */
@Injectable({
  providedIn: 'root'
})
export class ModelConverterService {

  constructor() { }

  convertRegion(region: any): Region {
    if (region.region === undefined) {
      return new Region(region.name);
    } else {
      return new Region(region.region);
    }
  }

  convertLanguage(lang: any): Language{
    if (lang.languagetag === undefined) {
      return new Language(lang.code,lang.name);
    } else {
      return new Language(undefined,lang.languagetag);
    }
  }

  convertBusinessUnit(bunit: any): BusinessUnit {
    if (bunit.bussinessunit === undefined) {
      return new BusinessUnit(bunit.name);
    } else {
      return new BusinessUnit(bunit.bussinessunit);
    }
  }

  convertCategory(data: any): Category {
    return new Category(data.name, data.parent_category, data.is_group);
  }

  convertDocument(data: any): Document {
    if( data.regions === undefined) {
      // @ts-ignore
      return new Document(
        data.name,
        data.category,
        data.file,
        [],
        [],
        [],
        null,
        null,
        data.important,
        null);
    } else {
      return new Document(
        data.name,
        data.category,
        data.file,
        this.convertRegions(data.regions),
        this.convertLangs(data.languages),
        this.convertBUnits(data.bussiness_units),
        data.extract,
        data.title,
        data.important,
        data.owner,
        data.creation
      );
    }

  }

  public convertRegions(regions: any[]): Region[] {
    return regions.map( region => this.convertRegion(region));
  }

  public convertLangs(langs: any[]): Language[] {
    return langs.map( lang => this.convertLanguage(lang));
  }

  public convertBUnits(bUnits: any[]): BusinessUnit[] {
    return bUnits.map( bunit => this.convertBusinessUnit(bunit));
  }

  public convertCategories(categories: any[]): Category[] {
    return categories.map(category => this.convertCategory(category));
  }

  public convertDocuments(documents: any[]): Document[] {
    return documents.map(doc => this.convertDocument(doc));
  }

}
