<div class="container" id="export-component">
    <div class="row">
        <div class="col-md-6">
            <div id="downloadDocument">
                <div id="header">
                    <div class="row vertical-align">
                        <div class="col-md-8">
                            <img [src]="logoBYDPath" alt="logoBYD" id="img-export-document">
                        </div>
                        <div class="col-md-4">
                            <h3 id="document-header">Document</h3>
                        </div>
                    </div>
                </div>
                <div id="line"></div>
            
                <h5>Document information:</h5>
                <p><b>Title:</b> {{ document.title}}</p>
                <p><b>Owner:</b> {{ document.owner}}</p>
                <p><b>Category:</b> {{ document.category }}</p>
            
                <p><b>Regions:</b>
                    {{ getRegions() }}
                </p>
                <p><b>Languages: </b>
                    {{ getLanguages() }}
                </p>
                <p><b>Business units: </b>
                    {{ getBunits() }}
                </p>
                <p><b>Link to download:</b> https://cms.eft-systems.de/private/files</p>
                <p> {{ fileNameWithOutPath() }}</p>
            </div>
        </div>
        <div class="col-md-6"></div>
    </div>
    <div class="row">
        <button (click)="downloadDocumentAsPDF()" class="btn btn-danger">Download</button>
    </div>
</div>



