import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Document } from '../../model/document';
import { AppSettings } from '../../app-settings';
import { ChangePageService } from 'src/app/services/change-page.service';
import { TotalItemsService } from 'src/app/services/total-items.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.css']
})
export class DocumentsComponent implements OnInit {

  @Input() documents: Document[];
  @Input() searchError: any;
  totalDocuments: number;
  docsPerPage: number = AppSettings.DOCS_PER_PAGE;
  currentPage: number = 1;
  true: boolean = true;
  isSpinnerVisible: boolean;

  constructor(private changePageService: ChangePageService,
              private totalItemsService: TotalItemsService,
              private spinnerService: SpinnerService ) { }

  ngOnInit(): void {
    this.totalItemsService.getStatus()
        .subscribe( total => this.totalDocuments = total);
    this.spinnerService.getStatus()
        .subscribe( data => this.isSpinnerVisible = data );
  }

  hasDocumentsResult(): boolean {
    return this.documents.length !== 0;
  }

  handlePageChange(n: any): void {
    this.changePageService.nextPage(n);
    this.setCurrentPage(n);
  }

  private setCurrentPage(n: number) {
    this.currentPage = n
  }
}
