
export class Language {

    name?: string;
    code?: string;

    constructor( code: string|undefined, name?: string) {
        this.name = name;
        this.code = code;
    }
}