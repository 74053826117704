import { Component, OnInit } from '@angular/core';
import { AppSettings } from '../../app-settings';

@Component({
  selector: 'app-no-docs-result',
  templateUrl: './no-docs-result.component.html',
  styleUrls: ['./no-docs-result.component.css']
})
export class NoDocsResultComponent implements OnInit {

  emptyImgPath: string = `../../../${AppSettings.IMAGES_DIR}/empty_img.png`

  constructor() { }

  ngOnInit(): void {
  }

}
