import { LocationStrategy } from '@angular/common';
import { Component } from '@angular/core';
import { Document } from '../../model/document';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent {

  currentDocuments: Document[];
  searchError: any;

  constructor(private location: LocationStrategy) {
    history.pushState(null, '', window.location.href)
    this.location.onPopState( () => {
      history.pushState(null, '', window.location.href)
    })
  }

  getDocuments(documents: Document[]) {
    this.currentDocuments = documents;
  }

  receiveError(newError: any) {
    this.searchError = newError;
  }
}
