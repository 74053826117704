
export abstract class AbstractFilterBuilder {
    field: string;
    table?: string;
    
    constructor(field: string, table?: string) { 
        this.field = field;
        this.table = table;
    }

    public abstract build(value: string): string;
}