<div class="container">
  <div class="fondo">
    <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-lg-1 col-md-0">
              <img [src]="documentImg" alt="logo_byd" width="80" height="80" class="d-inline-block align-text-top">
            </div>
            <div class="col-lg-10 col-md-12 pl-5 pt-2">
                <div class="nombreTit"> {{ document.title }}</div>{{document.important}}
              <div *ngIf="">
                <fa-icon [icon]="faStar"></fa-icon>
              </div>
            </div>
          </div>
          <hr >
          <div class="row">
            <div class="col-1"></div>
            <div class="col-11 row">
                <div class="badge bg-info datoBadge">
                  <fa-icon [icon]="faList"></fa-icon><b> {{ "Categoria" | translate }}</b><br>
                  {{ document.category }}
                </div>

                <div class="badge bg-info datoBadge">
                  <fa-icon [icon]="faGlobeAmericas"></fa-icon><b> {{ "Region" | translate }}</b><br>
                  {{ getRegions() }}
                </div>

              <div class="badge bg-info datoBadge">
                <fa-icon [icon]="faCalendar"></fa-icon> <b> {{ "Creado" | translate }}</b> <br>
                {{ document.creation| date:'yyyy-MM-dd HH:mm' }}
              </div>
            </div>

          </div>
          <br>

          <div class="row">
            <div class="col-1"></div>
            <div class="col-11 pt-3">
              <fa-icon [icon]="faLanguage"></fa-icon><b> {{ "Idioma" | translate }}</b> |
              {{ getLanguages() }}
            </div>
            <div class="col-8">
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-1"></div>
            <div class="col-11">
              <fa-icon [icon]="faLink"></fa-icon><b> Link</b> <br>
              https://docs.eft-systems.de/download/{{ document.name }}&_lang=en
            </div>
          </div>

          <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
              <br>
              <a class="btn btn-dark customBtn"
                 target="_blank"
                 href="https://docs.eft-systems.de/download/{{ document.name }}&_lang=en">
                <fa-icon [icon]="faEye"></fa-icon>
                {{ "Ver" | translate }}
              </a>

<!--              <a class="btn btn-dark customBtn"-->
<!--                 style="margin-left: .5em;"-->
<!--                 target="_blank"-->
<!--                 href="https://docs.eft-systems.de/download/{{ document.name }}&_lang=en">-->
<!--                <fa-icon [icon]="faDownload"></fa-icon>-->
<!--                {{ "Descargar" | translate }}-->
<!--              </a>-->
            </div>
          </div>
        </div>
  </div>
  </div>
  <br>
</div>
