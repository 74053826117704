import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  getStatus(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  updateIsAuthenticated(isAuthorized: boolean): void {
    this.isAuthenticated.next(isAuthorized);
  }

}
