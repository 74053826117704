import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BusinessUnit} from 'src/app/model/businessUnit';
import { Category} from 'src/app/model/category';
import { Region} from 'src/app/model/region';
import { Language } from 'src/app/model/language';
import { ApiService } from 'src/app/services/api.service';
import { ModelConverterService } from 'src/app/services/model-converter.service';
import { Document } from '../../model/document';
import { ChangePageService } from 'src/app/services/change-page.service';
import { AppSettings } from '../../app-settings';
import { TotalItemsService } from 'src/app/services/total-items.service';
import { ResponsiveMakerService } from 'src/app/services/responsive-maker.service';
import { SpinnerService } from 'src/app/services/spinner.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit{

  @Output() newDocumentsEvent = new EventEmitter<Document[]>();
  @Output() newSearchErrorEvent = new EventEmitter<any>();
  searchForm: FormGroup;
  languages: Language[];
  regions: Region[];
  // bUnits as businessUnits
  bUnits: BusinessUnit[];
  categories: Category[];
  noneSelected: string = AppSettings.NONE_VALUE_SELECTED;
  invalidSearchStyle: any = {
    "display": "none"
  }
  searchParams: any = {
    title: null,
    category: null,
    language: null,
    bunit: null,
    region: null
  }


  constructor(private fb: FormBuilder,
              private api: ApiService,
              private modelConverter: ModelConverterService,
              private changePage: ChangePageService,
              private totalItemsService: TotalItemsService,
              private responsiveMakerService: ResponsiveMakerService,
              private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.fetchDataForms();
    this.createForm();
    this.changePage.getStatus().subscribe( nextPage => {
      this.searchDocuments(this.searchParams,nextPage)
    });
  }



  // Fetch the data that will be consumed by the forms to display the options
  private fetchDataForms(): void {
    this.getLanguages();
    this.getRegions();
    this.getBusinessUnits();
    this.getCategories();

  }

  private getCategories(): void {
    this.api.getCategories()
            .subscribe(data => {
              this.categories = this.modelConverter.convertCategories(data.data);
            })
  }

  private getBusinessUnits(): void {
    this.api.getBusinessUnits()
            .subscribe(data => {
              this.bUnits = this.modelConverter.convertBUnits(data.data);
            })
  }


  private getRegions(): void {
    this.api.getRegions()
            .subscribe(data => {
              this.regions = this.modelConverter.convertRegions(data.data);
            })
  }

  private getLanguages(): void {
    this.api.getLanguages()
            .subscribe(data => {
              this.languages = this.modelConverter.convertLangs(data.data);
            });
  }

  private createForm(): void {
    this.searchForm = this.fb.group({
      title: null,
      category: null,
      language: null,
      bunit: null,
      region: null
    });
  }

  onSubmit() {
    this.setSearchParams(this.searchForm.value);
    if (!this.areEmptyParams(this.searchParams)) {
      this.searchDocuments(this.searchParams,1);
    } else {
      this.showErrorMessage();
    }
  }

  setSearchParams(formData: any) {
    this.searchParams['title'] = formData.title
    if (this.isValidValue(formData.category)) this.searchParams['category'] = formData.category
    if (this.isValidValue(formData.language)) this.searchParams['language'] = formData.language
    if (this.isValidValue(formData.region)) this.searchParams['region'] = formData.region
    if (this.isValidValue(formData.bunit)) this.searchParams['bunit'] = formData.bunit
  }

  private isValidValue(property: string): boolean {
    return property !== null
  }

  private areEmptyParams(searchParams: any): boolean {
    let result = true
    for (const property in searchParams) {
      if (searchParams[property] !== null) {
        return false
      }
    }

    return result;
  }

  private showErrorMessage(): void {
    this.invalidSearchStyle = {
      "display": "block",
      "color": "red"
    }
  }

  private searchDocuments(formData: any, nextPage: number) {
    this.spinnerService.changeVisbility(true)
    this.invalidSearchStyle = { "display": "none"};
    this.api.searchDocuments(formData, nextPage).subscribe(
      data => {
        this.onSuccessSearch(data);
      },
      err => {
        this.onErrorSearch(err);
      },
      () => this.onEndSearch()
    );
  }

  private onSuccessSearch(data: any) {
    this.spinnerService.changeVisbility(false)
    let documents = this.modelConverter.convertDocuments(data.documents.data);
    this.totalItemsService.nextTotal(data.total);
    this.newDocumentsEvent.emit(documents);

  }

  private onErrorSearch(err: any): void {
    this.spinnerService.changeVisbility(false)
    this.newSearchErrorEvent.emit(err);
  }

  private onEndSearch(): void {
    this.responsiveMakerService.verticalScreenAdjust();
  }
}
