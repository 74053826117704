import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  notFoundIconPath: string = `../../../${AppSettings.IMAGES_DIR}/notfound_icon.svg`

  constructor() { }

  ngOnInit(): void {
  }

}
