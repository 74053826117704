import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';
import { LanguageFilterBuilder } from '../model/filterBuilder/LanguageFilterBuilder';
import { RegionFilterBuilder } from '../model/filterBuilder/RegionFilterBuilder';
import { BunitFilterBuilder } from '../model/filterBuilder/BunitFilterBuilder'
import { CategoryFilterBuilder } from '../model/filterBuilder/CategoryFilterBuilder';
import { TitleFilterBuilder } from '../model/filterBuilder/TitleFilterBuilder';
import { ExtractFilterBuilder } from '../model/filterBuilder/ExtractFilterBuilder';
import { ImportantFilterBuilder } from '../model/filterBuilder/ImportantFilterBuilder';

@Injectable({
  providedIn: 'root'
})
export class FilterBuilderService {


  langFilterBuilder: LanguageFilterBuilder = new LanguageFilterBuilder('languagetag','LanguageMultiSelect');
  regionFilterBuilder: RegionFilterBuilder = new RegionFilterBuilder('region','RegionMultiSelect');
  bunitFilterBuilder: BunitFilterBuilder = new BunitFilterBuilder('bussinessunit','BussinessUnitMultiSelect',);
  categoryFilterBuilder: CategoryFilterBuilder = new CategoryFilterBuilder('category');
  extractFilterBuilder: ExtractFilterBuilder = new ExtractFilterBuilder('extract');

  constructor() { }

  getFilters(formData: any): string {
    if (Object.keys(formData).length === 0) {
      return ''
    } else {
      let res: string = this.getFilterParamsAux(formData)
      return `&filters=[${res}]`
    }
  }

  private getFilterParamsAux(formData: any): string {
    let result: any[] = [];
    if (this.isPropertySet(formData['title'])) result = result.concat(this.extractFilterBuilder.build(formData['title']));
    if (this.isPropertySet(formData['important_document'])) result = result.concat(this.extractFilterBuilder.build(formData['important_document']));
    if (this.isPropertySet(formData['category'])) result = result.concat(this.categoryFilterBuilder.build(formData['category']));
    if (this.isPropertySet(formData['bunit'])) result = result.concat(this.bunitFilterBuilder.build(formData['bunit']));
    if (this.isPropertySet(formData['language'])) result = result.concat(this.langFilterBuilder.build(formData['language']));
    if (this.isPropertySet(formData['region'])) result = result.concat(this.regionFilterBuilder.build(formData['region']));
    return result.join();
  }

  isPropertySet(property: string | null | undefined): boolean {
    return property !== null && property !== undefined && property.length !== 0 && property !== AppSettings.NONE_VALUE_SELECTED;
  }
}
