<br>
<div class="container-fluid" id="main">
    <div class="row">
        <app-search id="search-component"
                    class="col-md-4 col-sm-6"
                    (newDocumentsEvent)="getDocuments($event)"
                    (newSearchErrorEvent)="receiveError($event)">
        </app-search>
        <app-documents id="document-component"
                       class="col-md-7 col-sm-6"
                       [documents]="currentDocuments"
                       [searchError]="searchError">
        </app-documents>
    </div>
</div>
