import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ModelConverterService } from 'src/app/services/model-converter.service';
import { Document } from 'src/app/model/document';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-export-document',
  templateUrl: './export-document.component.html',
  styleUrls: ['./export-document.component.css']
})
export class ExportDocumentComponent implements OnInit {

  document: Document;
  logoBYDPath: string = `../../${AppSettings.IMAGES_DIR}/byd_logo.png`;

  constructor(private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private modelConverter: ModelConverterService) { }

  ngOnInit(): void {
    this.api.getSingleRecord('Document', this.activatedRoute.snapshot.paramMap.get('name'))
            .subscribe(
              (data: any) => {
                this.document = this.modelConverter.convertDocument(data.data);
              },
              error => console.error(error)
            )
  }

  getRegions(): string {
    let result: string = '';
    for (let region of this.document.regions) {
      result = `${result} ${region.name}, `
    }
    return result.substr(0, result.length-2);
  }

  getLanguages(): string {
    let result: string = '';
    for (let lang of this.document.languages) {
      result = `${result} ${lang.name}, `
    }
    return result.substr(0, result.length-2);
  }

  getBunits(): string {
    let result: string = '';
    for (let bunit of this.document.businessUnits) {
      result = `${result} ${bunit.name}, `
    }
    return result.substr(0, result.length-2);
  }

  fileNameWithOutPath(): string {
    return this.document.fileName.split("/",4)[3]
  }

  downloadDocumentAsPDF() {
    const data = document.getElementById('downloadDocument')!
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };

     var link = 'https://docs.eft-systems.de/download/'+ this.document.name+'&_lang=en';
     console.log(link);

    // html2canvas(data, options).then(canvas => {
    //   const img = canvas.toDataURL('image/PNG');
    //
    //   // Add image Canvas to PDF
    //   const bufferX = 20;
    //   const bufferY = 15;
    //   const imgProps = (doc as any).getImageProperties(img);
    //   const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //   doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
    //   return doc;
    // }).then((docResult) => {
    //   docResult.save(`${this.document.title}.pdf`);
    // });
  }
}
