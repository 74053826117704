import { Injectable } from '@angular/core';
import { getStatusText } from 'angular-in-memory-web-api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TotalItemsService {

  totalDocuments: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor() { }

  getStatus(): Observable<number> {
    return this.totalDocuments.asObservable()
  }

  nextTotal(total: number): void {
    this.totalDocuments.next(total);
  }

  getTotalDocuments() {
    return this.totalDocuments;
  }
}
