
<h3>{{ 'Buscar' | translate }}</h3>
<div class="fondo">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
      <div class="mb-3">
          <label class="form-label">{{ 'Buscar' | translate }}</label>
          <input type="text" class="form-control" formControlName="title">
      </div>

      <div class="mb-3">
          <label class="col-form-label">{{ 'Categoria' | translate }}</label>
          <select class="form-control select"  name="categories" formControlName="category">
              <option>{{ noneSelected }}</option>
              <option *ngFor="let category of categories">{{ category.name }}</option>
          </select>
      </div>

      <div class="mb-3">
          <label class="label-select">{{ 'Idioma' | translate }}</label>
          <select class="form-control select" name="languages" formControlName="language">
              <option>{{ noneSelected }}</option>
              <option *ngFor="let lang of languages"> {{ lang.name }}</option>
          </select>
      </div>

      <div class="mb-3">
          <label class="label-select">{{ 'Producto' | translate }}</label>
          <select class="form-control select" name="bUnits" formControlName="bunit">
              <option>{{ noneSelected }}</option>
              <option *ngFor="let bUnit of bUnits"> {{ bUnit.name }}</option>
          </select>
      </div>

      <div class="mb-3">
          <label class="label-select">{{ 'Region' | translate}}</label>
          <select class="form-control select" name="Regions" formControlName="region">
              <option>{{ noneSelected }}</option>
              <option *ngFor="let region of regions"> {{ region.name }}</option>
          </select>
      </div>

      <div class="container">
          <div class="row">
              <div class="col-md-2 formButtonSection">
                  <button class="btn btn-dark" type="submit">{{ 'Buscar' | translate }}</button>
              </div>
              <div class="col-md-4">
                  <p [ngStyle]="invalidSearchStyle" id="invalidSearch">{{ "Seleccione piola" | translate }}</p>
              </div>
              <div class="col-md-"></div>
          </div>
      </div>

  </form>
</div>
