
<app-navbar-cea></app-navbar-cea>
<ng-container >
    <div *ngIf="isLoading" class="fondo-cea mt-5 d-flex justify-content-center align-items-center">
        <h3 class="text-center"><fa-icon [icon]="faSpinner"  [spin]="true"></fa-icon>
          {{ 'Loading' | translate }}</h3>
      </div>
      <div *ngIf="!isLoading" class="battery-cea-home fondo-cea" >

            <span class="byd-titulo" >BYD <br> BATTERYBOX</span><br><br>

      </div>
      <div *ngIf="!isLoading" class="main-banner-white-home fondo-cea pb-5">
        <div class="container">
          <div class="row ">
            <div *ngFor="let producto of productos" class="col-lg-4 col-md-4 col-sm-6" [ngClass]="{'d-none': !categoriasPorProducto[producto.name1] || categoriasPorProducto[producto.name1].length === 0}">
              <div class="single-services">
                <div *ngIf="producto.show_title == '1'" class="container-img fondo-cea text-center" >
                  <img [src]="'https://cms.eft-systems.de/' + producto.img" class="product-image" alt="producto" >
                </div>
                <div class="services-content fondo-cea" style=" height: auto">
                  <div>
                    <div *ngIf="producto.show_title == '1'">
                      <h6 class="texto-blanco mb-3 texto-chico">{{ producto.name1 | translate }}</h6>
                    </div>
                    <div *ngFor="let cat of categoriasPorProducto[producto.name1]">
                      <div *ngIf="producto.show_title == '1' && cat.name !== 'Subsidy Form'" style="border-bottom: solid 0.5px rgb(255, 255, 255);" class="card-dow" (click)="toggleCollapse(producto.name1 + '_' + cat.name, producto.name1, cat.name, $event)">
                          <div class="d-flex justify-content-between linea">
                              <h5 class="mb-0" *ngIf="cat.name">
                                  <div class="btn btn-nopadding text-start texto-chico texto-crema " [attr.aria-expanded]="!collapsed[producto.name1 + '_' + cat.name]" [attr.aria-controls]="producto.name1 + '_' + cat.name">
                                    <span href="javascript:void(0);" (click)="closeIfSameCategory(producto.name1 + '_' + cat.name, $event)">
                                      {{ cat.name | translate }}</span>
                                  </div>
                                  <ng-container *ngIf="loadingRenting && lastOpenedId === producto.name1 + '_' + cat.name">
                                      <span>
                                          <fa-icon class="spinner" [icon]="faSpinner" [spin]="true"></fa-icon>
                                      </span>
                                  </ng-container>
                              </h5>
                              <span>
                                  <i href="javascript:void(0);" (click)="closeIfSameCategory(producto.name1 + '_' + cat.name, $event)" class="fas" [ngClass]="{'fa-chevron-up': !collapsed[producto.name1 + '_' + cat.name], 'fa-chevron-down': collapsed[producto.name1 + '_' + cat.name]}"></i>
                              </span>
                          </div>
                          <div>
                              <ng-container *ngIf="!collapsed[producto.name1 + '_' + cat.name]">
                                  <div *ngIf="mostrarMensajeNoDocumentos && !loadingRenting" class="alert alert-warning" role="alert" [innerHTML]="'No hay documentos disponibles en esta categoría.'|translate">
                                  </div>
                                  <ng-container *ngFor="let doc of documentos">
                                      <div [id]="producto.name1 + '_' + cat.name + '_' + doc.title">
                                          <ng-container *ngIf="doc.category === cat.name && productName === producto.name1 && !loadingRenting && !mostrarMensajeNoDocumentos">
                                              <div class="p-2 sublinea">
                                                <span *ngIf="producto.show_title == '1'" >
                                                  <a class="texto-crema" *ngIf="!doc.file.includes('https')" href="https://cms.eft-systems.de/{{ doc.file }}" target="_blank" style="font-size: smaller; ">
                                                    {{ doc.title }}
                                                  </a>
                                                  <a class="texto-crema" *ngIf="doc.file.includes('https')" href="{{ doc.file }}" target="_blank" style="font-size: smaller;"> 
                                                    {{ doc.title }}
                                                  </a>
                                                </span>                                          
                                              </div>
                                          </ng-container>
                                      </div>
                                  </ng-container>
                              </ng-container>
                          </div>
                      </div>
                  </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      
        
        
      </div>
      
</ng-container>
<app-footer-cea></app-footer-cea>

