import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { DocumentosCeaComponent } from './components/documentos-cea/documentos-cea.component';
import { DocumentosComponent } from './components/documentos/documentos.component';
import { DocumentComponent } from './components/documents/document.component';
import { ExportDocumentComponent } from './components/export-document/export-document.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
  {path: '', redirectTo: 'cea', pathMatch: 'full'},
  // {path: '**', component: DocumentosComponent} ,
  // {path: 'documentos', component: DocumentosComponent},
  {path: 'cea', component: DocumentosCeaComponent},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
