import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppSettings } from '../../app-settings';
import { Document } from '../../model/document';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { ModelConverterService } from 'src/app/services/model-converter.service';
import jsPDF from 'jspdf';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {
  faGlobeAmericas = faGlobeAmericas;
  faLanguage = faLanguage;
  faList = faListAlt;
  faLink = faLink;
  faCalendar = faCalendar;
  faEnvelope = faEnvelope;
  faDownload = faDownload;
  faStar = faStar;
  faEye = faEye;


  @Input() document: Document;
  fileIconPath: string = `../../../${AppSettings.IMAGES_DIR}/file_icon.svg`;
  infoIconPath: string = `../../../${AppSettings.IMAGES_DIR}/info_icon.svg`;
  downloadIconPath: string = `../../../${AppSettings.IMAGES_DIR}/download_icon.svg`;
  documentImg: string = `../../../assets/imgs/documentos.svg`;


  idioma: string;

  constructor(private api: ApiService, private modelConverter: ModelConverterService) {
    const urlParams = new URLSearchParams(window.location.search);
    this.idioma = urlParams.get('idioma') || 'predeterminado';
  }

  ngOnInit(): void {
    this.api.getSingleRecord('Document', this.document.name).subscribe(
      data => this.assignDocument(data),
      err => console.log(err)
    );

    console.log(this.document);
  }

  assignDocument(data: any) {
    this.document = this.modelConverter.convertDocument(data.data);
  }

  getLanguages(): string {
    let result = '';
    for (let lang of this.document.languages) {
      result = `${this.getLanguage(lang)}, ${result}`;
    }
    return result.substr(0, result.length - 2);
  }

  private getLanguage(lang: any): string { return lang.name; }

  getRegions(): string {
    let result = '';
    for (let region of this.document.regions) {
      result = `${this.getRegion(region)}, ${result}`;
    }
    return result.substr(0, result.length - 2);
  }

  private getRegion(region: any): string {
    return region.name
  }



  getInfo() {
    Swal.fire({
      iconHtml: 'i',
      title: `${this.document.name} info:`,
      html: this.getDescription(),
      confirmButtonColor: '#f27474'
    });
  }

  private getDescription(): string {
    return `<div>\
              <p><b>Owner: </b>${this.document.owner}</p>\
              <p><b>Creation: </b>${this.getCreationDate(this.document.creation)}</p>\
            </div>`
  }

  private getCreationDate(date: any): string {
    return date.substr(0,9);
  }

  private showSwalError(title: string): void {
    Swal.fire({
      icon: 'error',
      title: title,
      confirmButtonColor: '#f27474'
    })
  }

   getLink() {
    var link = 'https://docs.eft-systems.de/download/' + this.document.name+'&_lang=en';
    return encodeURIComponent(link);
  }
}
