<app-navbar></app-navbar>
<div *ngIf="isLoading" class="main-banner-white-home mt-5 d-flex justify-content-center align-items-center" style="background-color: #F8F8F8; height: 300px;">
  <h3 class="text-center"><fa-icon [icon]="faSpinner"  [spin]="true"></fa-icon>
    {{ 'Loading' | translate }}</h3>
</div>
<div *ngIf="!isLoading" class="main-banner-white-service" style="height:350px; background-color:#F8F8F8;">
  <div class=" pt-70 container" >
    <h1 class="text-start texto-negro titulo-chico" style="margin-top:5em; text-align:left;" [innerHTML]="'DOWNLOADS'|translate"></h1>
    <p class="text-start texto-negro mt-4 texto-chico" [innerHTML]="'Select the language and then download all the information about our products'|translate"></p>

  </div>
</div>
<div *ngIf="!isLoading" class="main-banner-white-home " style="background-color: #F8F8F8;">
  <div class="container">
    <div class="row justify-content-center">
      <div *ngFor="let producto of productos" class="col-lg-4 col-md-4 col-sm-6" [ngClass]="{'d-none': !categoriasPorProducto[producto.name1] || categoriasPorProducto[producto.name1].length === 0}">
        <div class="single-services">
          <div *ngIf="producto.show_title == '1'" class="container-img text-center" style="background-color: #F8F8F8; height: auto">
            <img [src]="'https://cms.eft-systems.de/' + producto.img" alt="" >
          </div>
          <div class="services-content" style="background-color: #F8F8F8; height: auto">
            <div>
              <div *ngIf="producto.show_title == '1'">
                <h6 class="texto-negro mb-3 texto-chico">{{ producto.name1 | translate }}</h6>
              </div>
              <div *ngFor="let cat of categoriasPorProducto[producto.name1]">
                <div *ngIf="producto.show_title == '1' && cat.name !== 'Subsidy Form'" style="border-bottom: solid 0.5px black;" class="card-dow" (click)="toggleCollapse(producto.name1 + '_' + cat.name, producto.name1, cat.name, $event)">
                    <div class="d-flex justify-content-between">
                        <h5 class="mb-0" *ngIf="cat.name">
                            <div class="btn btn-nopadding text-start texto-chico" [attr.aria-expanded]="!collapsed[producto.name1 + '_' + cat.name]" [attr.aria-controls]="producto.name1 + '_' + cat.name">
                              <span href="javascript:void(0);" (click)="closeIfSameCategory(producto.name1 + '_' + cat.name, $event)">
                                {{ cat.name | translate }}</span>
                            </div>
                            <ng-container *ngIf="loadingRenting && lastOpenedId === producto.name1 + '_' + cat.name">
                                <span>
                                    <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
                                </span>
                            </ng-container>
                        </h5>
                        <span>
                            <i href="javascript:void(0);" (click)="closeIfSameCategory(producto.name1 + '_' + cat.name, $event)" class="fas" [ngClass]="{'fa-chevron-up': !collapsed[producto.name1 + '_' + cat.name], 'fa-chevron-down': collapsed[producto.name1 + '_' + cat.name]}"></i>
                        </span>
                    </div>
                    <div>
                        <ng-container *ngIf="!collapsed[producto.name1 + '_' + cat.name]">
                            <div *ngIf="mostrarMensajeNoDocumentos && !loadingRenting" class="alert alert-warning" role="alert" [innerHTML]="'No hay documentos disponibles en esta categoría.'|translate">
                            </div>
                            <ng-container *ngFor="let doc of documentos">
                                <div [id]="producto.name1 + '_' + cat.name + '_' + doc.title">
                                    <ng-container *ngIf="doc.category === cat.name && productName === producto.name1 && !loadingRenting && !mostrarMensajeNoDocumentos">
                                        <div>
                                          <span *ngIf="producto.show_title == '1'">
                                            <a *ngIf="!doc.file.includes('https')" href="https://cms.eft-systems.de/{{ doc.file }}" target="_blank" style="font-size: smaller; color:black;">
                                              {{ doc.title }}
                                            </a>
                                            <a *ngIf="doc.file.includes('https')" href="{{ doc.file }}" target="_blank" style="font-size: smaller; color:black;"> 
                                              {{ doc.title }}
                                            </a>
                                          </span>                                          
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="container">
    <div class="row justify-content-right">
      <h5 style="padding-left: 30px;" [innerHTML]="'Otros documentos' | translate"></h5> <br><br>
      <ng-container *ngIf="productos.length > 0">
        <div class="col-12">
        <div *ngFor="let producto of productos" class="col-lg-4 col-md-4 col-sm-6" [ngClass]="{'d-none': !categoriasPorProducto[producto.name1] || categoriasPorProducto[producto.name1].length === 0}">
          <div class="services-content" style="background-color: #F8F8F8; height: auto" *ngIf="categoriasPorProducto[producto.name1] && categoriasPorProducto[producto.name1].length > 0">
            <div   *ngIf="producto.show_title == '1' && producto.name1 =='HVM' && producto.name1 =='LVL' && producto.name1 =='LVS'">
              <p class="texto-negro mb-3 mt-3 ">{{ producto.name1 }}</p>
            </div>
            <div *ngFor="let cat of categoriasPorProducto[producto.name1]">
            <div  *ngIf="producto.show_title == '0' && producto.name1 !== 'HVL' && producto.name1 !== 'Commercial' && producto.name1 !== 'Other'"  >
              <div style="border-bottom: solid 0.5px black;" class="card-dow" (click)="toggleCollapse(producto.name1 + '_' + cat.name, producto.name1, cat.name, $event)" >
                <div  class="d-flex justify-content-between">
                  <h5 class="mb-0" >
                     <div class="btn btn-nopadding text-start " [attr.aria-expanded]="!collapsed[producto.name1 + '_' + cat.name]" [attr.aria-controls]="producto.name1 + '_' + cat.name" href="javascript:void(0);" (click)="closeIfSameCategory(producto.name1 + '_' + cat.name, $event)">
                      {{ cat.name | translate }}
                    </div> 
                    <ng-container *ngIf="loadingRenting && lastOpenedId === producto.name1 + '_' + cat.name">
                      <span>
                        <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
                      </span>
                    </ng-container>
                  </h5>
                  <span>
                    <i class="fas" href="javascript:void(0);" (click)="closeIfSameCategory(producto.name1 + '_' + cat.name, $event)"[ngClass]="{'fa-chevron-up': !collapsed[producto.name1 + '_' + cat.name], 'fa-chevron-down': collapsed[producto.name1 + '_' + cat.name]}"></i>
                  </span>
                </div>
                <div>
                  <ng-container *ngIf="!collapsed[producto.name1 + '_' + cat.name]">
                    <div *ngIf="mostrarMensajeNoDocumentos && !loadingRenting" class="alert alert-warning" role="alert" [innerHTML]="'No hay documentos disponibles en esta categoría.'|translate">
                      
                    </div>
                    <ng-container *ngFor="let doc of documentos">
                      <div [id]="producto.name1 + '_' + cat.name + '_' + doc.title">
                        <ng-container *ngIf="doc.category === cat.name && productName === producto.name1 && !loadingRenting && !mostrarMensajeNoDocumentos">
                          <div>
                            <a  *ngIf="producto.show_title == '0' && producto.name1 !== 'HVL' && doc.title !== 'Pictures'" href="https://cms.eft-systems.de/{{ doc.file }}" target="_blank" style="font-size: smaller;color:black;">
                              {{ doc.title }} <br>
                            </a>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                    <ng-container *ngFor="let doc of documentos">
                      <div [id]="producto.name1 + '_' + cat.name + '_' + doc.title">
                        <ng-container *ngIf="doc.category === cat.name && productName === producto.name1 && !loadingRenting && !mostrarMensajeNoDocumentos">
                          <div>
                            <a *ngIf="producto.show_title == '0' && producto.name1 !== 'HVL' && doc.title === 'Pictures'" 
                            (click)="downloadMultipleFiles()"
                            style="font-size: smaller; color: black;">
                            {{ 'Pictures' }} <br>
                         </a>
                         
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </ng-container>
    </div>
  </div>
    </div>

  
  
</div>
<app-footer></app-footer>