import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { FilterBuilderService } from './filter-builder.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private basePath = '/api/resource';
  private getFilesPath = '/private/files';
  private categoriesPath = `${this.basePath}/Category`;
  private regionsPath = `${this.basePath}/RegionTag`;
  private languagesPath = `${this.basePath}/LanguageTag`;
  private businessUnitsPath = `${this.basePath}/BussinessUnitTag`;
  private documentsPath = `${this.basePath}/Document?order_by=important_document%20desc`;

  constructor(private http: HttpClient,
              private filterBuilder: FilterBuilderService ) { }

  private getHttp(path: string): Observable<any> {
    return this.http.get<any>(path);
  }

  getCategories(): Observable<any> {
    return this.getHttp(this.categoriesPath);
  }

  getRegions(): Observable<any> {
    return this.getHttp(this.regionsPath);
  }

  getLanguages(): Observable<any> {
    return this.getHttp(this.languagesPath);
  }

  getBusinessUnits(): Observable<any> {
    return this.getHttp(this.businessUnitsPath);
  }

  getDocuments(): Observable<any> {
    return this.getHttp(this.documentsPath)
  }

  private handleError(error: HttpErrorResponse) {
    // Client side or network error
    if (error.status === 0) {
      console.error('Error ocurred: ', error.error);
    } else {
    // Backend error
      console.error(
        `Backend returned code is: ${error.status}`,
        `Body is: ${error.error}`
      )
    }

    return throwError('An unkown error ocurred');
  }

  getFile(documentTitle: string): Observable<any> {
    return this.http.get(`${this.getFilesPath}/${documentTitle}`, {responseType: 'blob'})
  }

  searchDocuments(formData: any, nextPage: number): Observable<any> {
    return forkJoin([
      this.searchDocumentsAux(formData, nextPage),
      this.getTotal('Document', formData),
    ]).pipe(
      map((data: any[]) => {
        let documents: any = data[0];
        let total: any[] = data[1].message.stats._user_tags[0][1];
        return {
          documents: documents,
          total: total
        }
      })
    )
  }

  searchDocumentsAux(formData: any, nextPage: number): Observable<any> {
    return this.http.get(this.documentsPath, { params: this.getSearchParams(formData, nextPage)})
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  getTotal(doctype: string, formData: any): Observable<any> {
    return this.http.get(
      '/api/method/frappe.desk.reportview.get_sidebar_stats',
      {params: new HttpParams({fromString: `stats=["_user_tags"]&doctype=${doctype}${this.filterBuilder.getFilters(formData)}`})}
    )
  }

  getSearchParams(formData: any, nextPage: number): HttpParams {
    return new HttpParams({fromString: `${this.getDocFields()}&${this.getPageParams(nextPage)}${this.filterBuilder.getFilters(formData)}`});
  }

  private getDocFields(): string {
    return 'fields=["name","category","file"]';
  }

  private getPageParams(nextPage: number): string {
    const offset = AppSettings.getOffset(nextPage);
    return `limit_start=${offset}&limit_page_length=${AppSettings.DOCS_PER_PAGE}`
  }

  getSingleRecord(doctype: string, id: any) {
    return this.http.get(`${this.basePath}/${doctype}/${id}`);
  }
  public getCategorias(): Observable<any[]> {
    return this.http.get<any[]>('/api/method/docs_management.documents.getCategorias');
  }
  
  public getProductos(): Observable<any[]> {
    return this.http.get<any[]>('/api/method/docs_management.documents.getProductos');
  }
  getDocumentos(formData: any): Observable<any> {
    return this.http.post<any>('/api/method/docs_management.documents.getDocuments', formData);
  }  
  getCategoriasPorProducto(producto: string) {
    return this.http.get(`/api/method/docs_management.documents.getCategoriasPorProducto?producto=${producto}`);
  }
}
